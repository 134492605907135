@import 'src/assets/stylesheets/variables';

.button-default-light {
	background: #fff5e1;
	border: 1px solid #fff5e1;
	border-radius: 8px;
	height: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 4px 10px;
	font-weight: 500;
	font-size: 16px;
	color: #bcbcbc;
	min-width: 5rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	text-decoration: none;
}

.button-default {
	background: #ffd88b;
	/* Primary */
	border: 1px solid #ffb21b;
	border-radius: 8px;
	height: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 4px 10px;
	font-weight: 500;
	font-size: 16px;
	color: #000000;
	min-width: 5rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	text-decoration: none;
}

.btn-links-1 {
	font-weight: 500;
	font-size: 14px;
	color: $colortheme1;
	text-decoration: none;
	&:hover {
		color: $colortheme1;
		text-decoration: underline !important;
	}
}

.button-primary,
a.button-primary[href] {
	background: #fff5e1;
	border: 1px solid #ffb21b;
	/* Primary */

	border-radius: 10px;
	height: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 8px 20px;
	font-weight: 500;
	font-size: 16px;
	color: #000000;
	min-width: 5rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	text-decoration: none;

	&:hover {
		background: #fff;
		border-color: $color-theme;
	}

	&.active {
		background: #ffb21b;
		&:hover {
			background: #ffb21b;
			border-color: $color-theme;
		}
	}

	&.ui-dark {
		border-color: #000;
		color: #000;
		background: #fff;
		&.active {
			background: #000;
			color: #fff;
		}
	}

	&.ui-ghost {
		border-color: #ffb21b;
		color: #000;
		background: #fff;
		padding-left: 10px;
		padding-right: 15px;
		padding-top: 4px;
		padding-bottom: 4px;
		height: auto;
		&.active {
			background: #000;
			color: #fff;
		}
		svg {
			margin-right: 10px;
		}
	}

	&.ui-light-1 {
		background: #fff5e1;
		&:hover {
			background: #ffb21b;
			border-color: $color-theme;
		}
	}

	&.ui-dark-1 {
		background: #ffb21b;
		&:hover {
			background: #ffb21b;
		}
	}

	&.login-btns {
		padding: 12px 50px;
		border-color: #ffd88b;
		line-height: 24px;
		height: auto;
	}

	&.disabled {
		background: #fff5e1;
		color: #b9b9b9;
		border-color: #fff5e1;
		cursor: text;
		&:hover {
			background: #fff5e1;
			color: #b9b9b9;
		}
	}

	&.p-button {
		.p-button-icon {
			font-weight: 500;
			font-size: 16px;
			color: #000000;
		}
		.p-button-label {
			font-weight: 500;
			font-size: 16px;
			color: #000000;
		}

		&:hover {
			.p-button-icon {
				color: #000000;
			}
		}
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}
}

.view-link {
	margin-left: 1rem;
	position: relative;
	display: inline-block;
	height: 20px;
	width: fit-content;
	color: $color-theme !important;
	font-size: 0.875rem;
	line-height: 1.3rem;
	letter-spacing: 0.35px;
	font-weight: 500;
	transition: all 1s ease-in-out;
	text-decoration: none;

	&:hover {
		text-decoration: none;
		color: $color-theme;

		&::after {
			background-color: $color-theme;
			width: 50%;
			left: 25%;
			transition: 0.2s ease-out all 0.1s;
		}
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		height: 1px !important;
		width: 100%;
		left: 0;
		background-color: $color-theme !important;
		transition: 0.4s ease-out all 0.1s;
	}
}
