@import './variables';
@import './common/_buttons';

body {
	margin: 0;
	&.fonts-roboto {
		font-family: 'roboto-pt';
		font-size: 0.875rem;
		.p-component {
			font-family: 'roboto-pt';
			font-size: 0.875rem;
		}
	}
	.p-inputtext {
		font-family: 'roboto-pt';
	}
}

.fonts-roboto {
	font-family: 'roboto-pt';
	font-size: 0.875rem;
}

/* scroll */
.scroll-off {
	overflow: hidden !important;
}
.scroll-on {
	overflow: auto !important;
}

/* common */
.ui-divider {
	border-bottom: 1px solid $border1;
}

/* dropdown menu */
.dropdown-ui-1 {
	position: relative;
	.dropdown-toggle {
		border: 1px solid #d8d8d8;
		border-radius: 8px;
		padding: 10px 15px;
		color: #000;
		text-decoration: none;
		&::after {
			content: '\f0d7';
			font: normal normal normal 15px/1 FontAwesome;
			margin-left: 10px;
		}
	}
	.dropdown-ui-container {
		display: none;
		opacity: 0;
		padding: 15px 0;
		margin: 0;
		list-style-type: none;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		position: absolute;
		left: 0;
		z-index: 9;
		min-width: 14rem;
		transition: ease 0.1s;
		@include lg-mobile {
			opacity: 1;
		}
		&.dropdown-ui-right {
			left: auto;
			right: 0;
		}
		.dropdown-ui-items {
			margin-bottom: 5px;
			position: relative;
			padding: 0;
			&.active {
				background: $colorthemebg1;
				.dropdown-ui-links {
					&::before {
						background: $colortheme1;
					}
				}
				&::after {
					background: $colortheme1;
				}
			}
		}
		.dropdown-ui-links {
			padding: 8px 15px 8px 30px;
			display: block;
			color: #000;
			font-weight: 500;
			text-decoration: none;
			&::before {
				content: '';
				width: 4px;
				position: absolute;
				left: 13px;
				top: 0;
				bottom: 0;
				background: #fff;
			}
			&:hover {
				background: $colorthemebg1;
				&::before {
					background: $colortheme1;
				}
			}
		}

		/* */
		&.cd-toggle-ui {
			display: none;
			opacity: 1;
		}
		/* */
		&.click-container {
			display: none;
			opacity: 1;
		}
	}

	&:hover,
	&.show-ui {
		.dropdown-ui-container {
			display: block;
			opacity: 1;
		}
	}
}
ul.dropdown-ui-1 {
	list-style-type: none;
	padding: 0;
}

/* dialog popup */
p-dialog {
	.p-dialog {
		.p-dialog-header {
			.p-dialog-header-icons {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}
	}
}

/* scrollbar */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	border-radius: 16px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	background-color: #c1c1c1;
	border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: $color-theme;
}

/* css */
.p-p-grid {
	&.p-grid {
		margin-right: 0;
		margin-left: 0;
		margin-top: 0;
	}
}

/* */
.scroll-auto {
	overflow: auto;
}

#resultList {
	overflow: auto;
}

/* */
.no-data-info {
	text-align: center;
	padding: 50px 0;
	.n-d-icons {
		margin-bottom: 12px;
		svg {
			max-width: 50px;

			path {
				fill: $color-theme;
			}
		}
	}
	.n-d-h1 {
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
	}
}

/* input switch */
.toggle-row {
	background: $color-theme-background;
	padding: 15px 15px;
	border-bottom: 1px solid #dedede;
	margin-bottom: 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.t-r-label {
		font-weight: 500;
		color: #000;
		font-size: 14px;
	}
	.t-r-input-switch {
	}
	p-inputswitch {
		.p-inputswitch {
			width: 22px;
			height: 13px;
			.p-inputswitch-slider {
				border: 1px solid #000;
				background: #fff;
				&:before {
					width: 7px;
					height: 7px;
					background: #000;
					left: 2.5px;
					margin-top: -3px;
				}
				&:hover {
					background: #fff;
				}
			}
			&.p-inputswitch-checked {
				.p-inputswitch-slider {
					border-color: $color-theme;
					background: $color-theme;
					&::before {
						left: -10.5px;
						background: #fff;
					}
				}
				&:hover {
					.p-inputswitch-slider {
						background: $color-theme;
					}
				}
			}
		}
	}
}

/* */
.cursor-pointer {
	cursor: pointer;
}

/* headings */
.ui-heading-1 {
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 25px;
	font-weight: 600;
}
.ui-heading-2 {
	font-size: 22px;
	margin-top: 0;
	margin-bottom: 15px;
}

.p-inputtext {
	//font-weight: 400!important;
}

.form-control-t1 {
	//font-weight: 400!important;
}

.p-listbox-item.p-highlight {
	background-color: var(--theme-background, red) !important;
}

/* sub search result info in page */
.search-result-info {
	padding: 10px 15px 0;
	margin: 0;
	font-weight: 400;
	font-size: 14px;
	color: #666;
	.search-result-text {
		color: $color-theme;
		font-weight: 500;
	}
}


/* */
.white-space-nowrap {
	white-space: nowrap !important;
}

/* */
.status-highlight {
	&.status-success {
.p-dropdown-label {
	&.p-inputtext {
color: $color-theme;
	}
}


	}

	&.status-unsuccess {
		.p-dropdown-label {
			&.p-inputtext {
		color: red;
			}
		}
		
		
			}
		}