@import 'src/assets/stylesheets/variables';

.joyride-step__holder {
	font-family: 'roboto-pt' !important;
	.joyride-step__container {
		max-width: 350px !important;
		min-height: 138px;
		background: #ffffff;
		border-radius: 12px;
		padding: 16px;
		.joyride-step__close {
			max-width: 10px;
		}
		.joyride-step__header {
			padding: 0;
		}
		.steps-h1 {
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #000000;
			margin-bottom: 10px;
			margin-top: 0;
		}
		.steps-h2 {
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			color: #000000;
			margin-bottom: 0px;
			margin-top: 0;
		}

		.joyride-step__body {
			padding: 0;
		}

		.joyride-step__footer {
			margin-top: 20px;
			flex-direction: row-reverse;
			padding-left: 0;

			.joyride-step__counter {
				font-weight: 400;
				font-size: 12px;
				line-height: 14px;
				color: #000000;
			}

			.joyride-step__button {
				.joyride-button {
					background: #ffb21b !important;
					border-radius: 8px;
					padding: 8px 16px;
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: #222222 !important;
					border: 1px solid #ffb21b !important;
					&:hover {
						background: #fff !important;
					}
				}
				&.joyride-step__prev-container {
					.joyride-button {
						background: #fff !important;
					}
				}
			}
		}
	}
}
