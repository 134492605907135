@import "src/assets/stylesheets/variables";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.theme-color {
    color: $color-theme  !important;

}
.current {
    background: orange !important;
  }
